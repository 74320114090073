import { GoogleOAuthProvider } from "@react-oauth/google";

const GoogleAuth = props => {
	if ( props?.withGoogleAuth ) {
		return (
			<GoogleOAuthProvider clientId = { process.env.GOOGLE_SIGNIN_CLIENT_ID }>
				{props.children}
			</GoogleOAuthProvider>
		);
	}

	return props.children;
};

export default GoogleAuth;
